<template>
  <div class="profile-section__games">
    <img class="img-wrapper" src="@/assets/mars-games.png" alt="" />
    <div class="profile-section__games__right">
      <p>{{ $t("learn-with-games") }}</p>
      <button @click="openGamesModal">
        <router-link to="/mars-games-typing" class="mars-games__game-wrapper">{{
          $t("play")
        }}</router-link>
      </button>
    </div>
    <el-dialog v-model="gamesModal">
      <router-link to="/mars-games-typing" class="mars-games__game-wrapper">
        <img src="@/assets/mars-typing.png" alt="mars-typing" />
        <h3>Mars Typing</h3>
      </router-link>
      <!-- <games-modal /> -->
    </el-dialog>
  </div>
</template>

<script setup>
import "./style/mars-games.css";
import { ref } from "vue";
import { useGtag } from "vue-gtag-next";

const gtag = useGtag();
const gamesModal = ref(false);

const openGamesModal = () => {
  gtag.event("mars-games-typing", {
    event_category: "mars-games",
    event_label: "mars-games-typing",
    value: 1,
  });
};
</script>
