<template>
  <div class="avatar-dialog1">
    <el-tabs v-model="selectedTab">
      <el-tab-pane label="Rasm tanlash" name="picture">
        <el-upload
          v-if="!fileList.length"
          class="upload-demo"
          drag
          accept="image/*"
          :auto-upload="false"
          v-model:file-list="fileList"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">{{ $t("avatar.file") }}</div>
        </el-upload>
        <div v-else class="relative bg-gray-200 p-2 rounded mb-5">
          <img
            :src="uploadedImg"
            alt=""
            class="w-[150px] h-[150px] rounded-full object-contain mx-auto"
            :style="{ backgroundColor: avatarBgColors[2] }"
          />
          <button
            class="absolute top-2 right-2 z-11 text-primary500"
            @click="fileList = []"
          >
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Avatarlar" name="avatars">
        <div class="flex flex-wrap gap-2 mb-5">
          <img
            v-for="(avatar, index) in avatars"
            :key="index"
            @click="selectedAvatar = index"
            :src="mediaLink + avatar"
            alt="profile-photo"
            :style="{ backgroundColor: avatarBgColors[index % 10] }"
            class="w-12 h-12 rounded-full object-contain cursor-pointer border-2 border-solid"
            :class="{
              'border-primary500': index === selectedAvatar,
            }"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-button
      @click="postImg"
      type="primary"
      class="w-full"
      :disabled="
        (!fileList.length && selectedTab === 'picture') ||
        (!selectedAvatar && selectedTab === 'avatars')
      "
    >
      {{ $t("change-avatar") }}
    </el-button>
  </div>
</template>
<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { UploadFilled } from "@element-plus/icons-vue";
import { postAvatarImg } from "@/service/post-avatar-img";
import { ElNotification, ElMessage } from "element-plus";
import { getAvatars } from "@/service/get-avatars";
import { mediaLink } from "@/apiConfig/mediaLink";
import { useStore } from "vuex";

const props = defineProps(["student_id"]);
const emits = defineEmits(["closeModal"]);
const store = useStore();
const fileList = ref([]);
const lang = localStorage.getItem("lang");
const selectedTab = ref("picture");
const selectedAvatar = ref(null);

const postImg = async () => {
  if (selectedTab.value === "avatars") {
    await postAvatarImg(null, selectedAvatar.value, props.student_id)
      .then(() => {
        success();
      })
      .catch((err) => {
        ElMessage.error(err);
      });
  } else if (selectedTab.value === "picture") {
    const formData = new FormData();
    formData.append("file", fileList.value[0]?.raw);
    await postAvatarImg(formData, null, props.student_id)
      .then(() => {
        success();
      })
      .catch((err) => {
        ElMessage.error(err);
      });
  }
};

function success() {
  ElNotification({
    message: lang == "uz" ? "Rasm yangilandi!" : "Изображение обновлено!",
    type: "success",
  });
  fileList.value = [];
  selectedAvatar.value = null;

  store.dispatch("getMe");
  emits("closeModal", false);
}

const avatars = ref([]);

const getAvatarImgs = async () => {
  avatars.value = await getAvatars()
    .then((res) => res.data)
    .catch(() => []);
};

onBeforeMount(() => {
  getAvatarImgs();
});

const avatarBgColors = {
  0: "#FBF0B2",
  1: "#FFE4D6",
  2: "#D2E0FB",
  3: "#DFCCFB",
  4: "#D2E0FB",
  5: "#DBFBB2",
  6: "#B2FBCF",
  7: "#FBB2EF",
  8: "#FBF0B2",
  9: "#DFCCFB",
};

const uploadedImg = computed(() => {
  if (!fileList.value.length) return "";
  const rawFile = fileList.value[0]?.raw;
  return URL.createObjectURL(rawFile);
});
</script>
