<template>
  <div
    v-if="imageUrl?.length"
    class="rounded-full border-2 border-solid profile-img relative"
    :class="
      (rank,
      {
        'w-[150px] h-[150px]': size === 'large',
        'w-[40px] h-[40px]': size === 'small',
      })
    "
  >
    <el-image
      v-if="preview"
      style="width: 100%; height: 100%; border-radius: 50%"
      :src="mediaLink + imageUrl"
      :zoom-rate="1.2"
      :max-scale="7"
      :min-scale="0.2"
      :initial-index="4"
      fit="contain"
      :preview-src-list="[mediaLink + imageUrl]"
      lazy
    />
    <img
      v-else
      :src="mediaLink + imageUrl"
      alt="avatar"
      class="w-full h-full rounded-full object-contain"
    />
    <button
      v-if="canChange"
      class="absolute -bottom-5 left-1/2 -translate-x-1/2"
      @click="openAvatarDialog"
    >
      <img src="@/assets/avatar-replace.svg" alt="" />
    </button>
  </div>
  <div
    v-else
    class="rounded-full border-2 border-solid profile-img relative"
    :class="
      (rank,
      {
        'w-[150px] h-[150px]': size === 'large',
        'w-[40px] h-[40px]': size === 'small',
      })
    "
  >
    <img
      src="@/assets/default-user-img.jpg"
      alt="avatar"
      class="w-full h-full rounded-full object-contain"
    />
    <button
      v-if="canChange"
      class="absolute -bottom-5 left-1/2 -translate-x-1/2"
      @click="openAvatarDialog"
    >
      <img src="@/assets/avatar-replace.svg" alt="" />
    </button>
  </div>

  <el-dialog
    v-model="avatarDialog"
    width="400px"
    align-center
    :title="$t('change-avatar')"
  >
    <AvatarDialog @closeModal="avatarDialog = false" :student_id="student_id" />
  </el-dialog>
</template>

<script setup>
import { mediaLink } from "@/apiConfig/mediaLink";
import AvatarDialog from "@/components/AvatarDialog.vue";
import { useGtag } from "vue-gtag-next";

import { ref, defineProps } from "vue";

const props = defineProps([
  "imageUrl",
  "canChange",
  "student_id",
  "size",
  "rank",
  "preview",
]);

const avatarDialog = ref(false);

const gtag = useGtag();
const openAvatarDialog = () => {
  gtag.event("avatar-change", {
    event_category: "avatar",
    event_label: "avatar-change",
    value: 1,
  });
  avatarDialog.value = true;
};
</script>

<style lang="scss">
.profile-img {
  background-color: #dbd2fb;
  border: 3px solid #505b65;
  &.Iron {
    background-color: #dbd2fb;
    border: 3px solid #505b65;
  }

  &.Bronze {
    border: 3px solid #c26e2a;
    background-color: #dbd2fb;
  }

  &.Silver {
    border: 3px solid #96acca;
    background-color: #febdb8;
  }

  &.Gold {
    border: 3px solid #fdc61d;
    background-color: #c7ebfc;
  }

  &.Platinum,
  &.Marsianin {
    border: 3px solid #c7f1d0;

    border-image-source: radial-gradient(
        72.92% 72.92% at 17.71% 12.5%,
        #f4c287 0%,
        #e12bee 36%,
        #5744ec 70.5%,
        #27bedb 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
}
</style>
