<template>
  <div class="courses-list">
    <div class="courses-list__header">
      <div class="flex items-center gap-1">
        <img src="@/assets/study_logo.png" alt="" width="30" />
        <h2 class="text-titleBlue text-[30px] font-bold">
          {{ $t("courses") }}
        </h2>
      </div>
    </div>
    <swiper
      :modules="[Navigation]"
      :slides-per-view="3"
      :navigation="true"
      :space-between="20"
      :breakpoints="{
        // when window width is >= 1024px
        1024: {
          slidesPerView: 3,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
        },
        // when window width is < 640px (default)
        320: {
          slidesPerView: 1,
        },
      }"
    >
      <swiper-slide v-for="(course, i) in allCourses" :key="i">
        <the-course :course="course" :reload="reload" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch, defineProps } from "vue";
import TheCourse from "@/entities/TheCourse/TheCourse.vue";
import { getAllCourses } from "./api/get-courses.js";
import "./style/courses-list.css";
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";

import { Navigation } from "swiper";

const store = useStore();

const props = defineProps(["reload"]);

const studentCourses = ref([]);

const updateStudentCourses = () => {
  studentCourses.value = store.state.student.studentInfo?.courses || [];
};

// Watch the Vuex store for changes to studentCourses and update the local ref
watch(
  () => store.state.student.studentInfo?.courses,
  (newValue) => {
    studentCourses.value = newValue || [];
  },
  { immediate: true }
);

const freeCourses = ref([]);

const getCourses = async () => {
  const response = await getAllCourses("free")
    .then((res) => res.data)
    .catch(() => []);
  freeCourses.value = response.map((course) => ({
    ...course,
    type: "free",
  }));
};

const allCourses = computed(() => [
  ...studentCourses.value,
  ...freeCourses.value,
]);

onBeforeMount(async () => {
  updateStudentCourses(); // Update local ref after fetching
  await getCourses();
});
</script>
<style lang="scss">
.courses-list {
  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 50%;
    &::after {
      font-size: 14px;
      color: #424242;
    }
  }
}
</style>
