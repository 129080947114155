<template>
  <div v-loading="loading">
    <div
      v-if="images.length"
      class="w-full rounded-md bg-gray-300 overflow-hidden relative"
    >
      <Cropper
        :src="images"
        :stencil-props="{
          aspectRatio: 4 / 5,
        }"
        @change="onChange"
      />
      <button
        class="absolute top-2 right-2 z-11 text-primary500"
        @click="removeImages"
      >
        <i class="fa-solid fa-circle-xmark"></i>
      </button>
    </div>

    <el-upload
      v-else
      :auto-upload="false"
      drag
      v-model:file-list="fileList"
      :limit="1"
    >
      <img src="@/assets/cloud-computing.png" alt="" class="mx-auto" />
      <div class="el-upload__text">
        {{ $t("select-file") }}
      </div>
    </el-upload>
    <el-input
      type="textarea"
      v-model="description"
      :placeholder="$t('header.header-create-post.post-inp')"
      clearable
      class="mt-2 rounded-md"
      :resize="false"
      show-word-limit
      maxlength="100"
    >
    </el-input>
    <el-button
      @click="createPost"
      :disabled="!fileList.length || !description.length"
      type="primary"
      class="mt-2 w-full"
      size="large rounded-md"
      >Post</el-button
    >
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { postPost } from "./api/create-post";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

const store = useStore();
const studentInfo = store.state.student.studentInfo;
const description = ref("");
const fileList = ref([]);
const uploadImg = ref(false);
const uploadedImg = ref([]);
const loading = ref(false);
const coordinates = ref(null);
const cropperResult = ref(null);

const emits = defineEmits(["close"]);

watch(
  () => store.state.student.studentInfo,
  (newValue) => {
    studentInfo.value = newValue;
  }
);

const onChange = ({ coordinates: coords, canvas }) => {
  coordinates.value = coords;
  cropperResult.value = canvas;
};

const removeImages = () => {
  fileList.value = [];
  coordinates.value = null;
  cropperResult.value = null;
};

const createPost = async () => {
  loading.value = true;
  const formData = new FormData();

  if (cropperResult.value) {
    // Convert cropped canvas to blob
    cropperResult.value.toBlob(async (blob) => {
      formData.append("files", blob, fileList.value[0]?.raw.name);

      await postPost(formData, description.value)
        .then(() => {
          emits("close");
          setTimeout(() => {
            clearData();
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          ElMessage.error(err);
        });

      loading.value = false;
    }, "image/jpeg");
  }
};

const clearData = () => {
  description.value = "";
  fileList.value = [];
  uploadedImg.value = [];
  uploadImg.value = false;
  coordinates.value = null;
  cropperResult.value = null;
};

const images = computed(() => {
  if (!fileList.value.length) return [];
  const rawFile = fileList.value[0]?.raw;
  return URL.createObjectURL(rawFile);
});
</script>
