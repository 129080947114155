<template>
  <div class="ans-and-ques">
    <div class="h-screen fixed top-0 left-0 bg-[#00000057] w-screen">
      <div class="flex w-full h-screen items-center justify-center">
        <div class="max-w-[600px] bg-white rounded-xl p-5 h-auto">
          <p class="text-center text-xl font-bold text-titleBlue !w-full">
            {{ $t("birth-certificate") }}
          </p>
          <el-alert type="warning" show-icon :closable="false" class="mt-5">
            <div class="!w-[100%]">
              {{ $t("metric-alert") }}
            </div>
          </el-alert>
          <form action="" class="mt-5" @submit.prevent="sendData">
            <label for="firstname" class="text-sm font-medium"
              ><span class="text-red-400">*</span>{{ $t("serial-number") }}
            </label>
            <el-input
              v-model="serialNumber"
              :placeholder="$t('metric-number') + ' (min-6)'"
              class="input-with-select"
              type="number"
              clearable
              size="large"
            >
              <template #prepend>
                <el-select
                  v-model="serialLetter"
                  :placeholder="$t('metric-seria')"
                  style="width: 140px"
                  filterable
                  size="large"
                  clearable
                >
                  <el-option
                    v-for="seria in serialLetters"
                    :key="seria"
                    :label="seria"
                    :value="seria"
                  />
                </el-select>
              </template>
            </el-input>

            <label for="lastname" class="text-sm font-medium mt-5 inline-block"
              ><span class="text-red-400">*</span>{{ $t("metric-img") }}
            </label>
            <el-upload
              :auto-upload="false"
              class="rounded-xl border border-solid overflow-hidden border-[#e5e5e5] w-full"
              drag
              v-model:file-list="fileList"
              :limit="1"
              accept="image/*"
            >
              <img
                src="@/assets/cloud-computing.png"
                alt=""
                class="mx-auto h-[50px]"
              />
              <div class="el-upload__text">
                {{ $t("select-file") }}
              </div>
            </el-upload>
            <button
              :disabled="
                !fileList.length ||
                serialNumber.length < 6 ||
                !serialLetter.length
              "
              type="submit"
              class="mt-5 w-full p-3 rounded-md bg-primary500 text-white font-bold disabled:opacity-30"
            >
              {{ $t("signup.confirm") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import apiV2 from "@/apiConfig/apiV2";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const serialNumber = ref("");
const fileList = ref([]);
const serialLetter = ref("");

const store = useStore();
const { t } = useI18n();

function sendData() {
  const data = new FormData();
  data.set("file", fileList.value[0]?.raw);
  data.set("serial", serialLetter.value);
  data.set("number", serialNumber.value);

  apiV2
    .post("/reports/metric", data)
    .then(() => {
      ElMessage.success(t("success"));
      store.dispatch("getMe");
    })
    .catch((err) => {
      ElMessage.error(err);
    });
}

const serialLetters = [
  "I-AN",
  "I-BH",
  "I-FR",
  "I-GZ",
  "I-HR",
  "II-AN",
  "II-BH",
  "II-FR",
  "II-GZ",
  "II-HR",
  "III-AN",
  "III-BH",
  "III-FR",
  "III-GZ",
  "III-HR",
  "III-KK",
  "III-NA",
  "III-NV",
  "III-QD",
  "III-QQ",
  "III-SM",
  "III-SR",
  "III-SU",
  "III-TN",
  "III-TV",
  "II-KK",
  "II-KS",
  "II-NA",
  "II-NV",
  "II-QD",
  "II-QQ",
  "II-SM",
  "II-SR",
  "II-SU",
  "II-TN",
  "II-TS",
  "II-TV",
  "I-KK",
  "I-NA",
  "I-NV",
  "I-QD",
  "I-QQ",
  "I-SM",
  "I-SR",
  "I-SU",
  "I-TN",
  "I-TV",
  "T",
  "TA",
  "TT",
  "ww",
];
</script>
