<template>
  <div class="rating-filter bg-white py-5 mt-5">
    <div class="container">
      <div
        class="flex justify-center lg:justify-between items-center gap-5 flex-wrap"
      >
        <h2 class="text-[30px] font-bold text-titleBlue">
          {{ $t("main.rating.heading") }}
        </h2>
        <el-tabs v-model="ratingType" class="demo-tabs">
          <el-tab-pane :label="$t('main.rating.tabs.clans')" name="2" />
          <el-tab-pane :label="$t('main.rating.tabs.group')" name="1" />
          <el-tab-pane :label="$t('main.rating.tabs.all')" name="0" />
        </el-tabs>
        <el-select
          size="large"
          class="w-[200px]"
          v-model="ratingTime"
          filterable
        >
          <el-option
            v-for="time in ratingTimeList"
            :key="time.value"
            :value="time.value"
            :label="time.label"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  emits: ["ratingTypeChanged", "ratingTimeChanged"],
  setup(_, { emit }) {
    const store = useStore();
    const ratingType = ref("1");
    const studentGroups = computed(
      () => store.state.student.studentInfo.groups
    );

    const { t } = useI18n();

    const ratingTime = ref("");

    watch(ratingTime, () => {
      emit("ratingTimeChanged", {
        time: ratingTime.value,
      });
    });

    const ratingTimeList = [
      {
        value: "daily",
        label: t("main.rating.sort.daily"),
      },
      {
        value: "weekly",
        label: t("main.rating.sort.weekly"),
      },
      {
        value: "monthly",
        label: t("main.rating.sort.monthly"),
      },
    ];
    const ratingTypeList = [
      {
        value: "all",
        label: t("main.rating.tabs.all"),
      },
      {
        value: "clans",
        label: t("main.rating.tabs.clans"),
      },
      {
        value: "group",
        label: t("main.rating.tabs.group"),
      },
    ];

    onBeforeMount(() => {
      ratingTime.value = "monthly";
    });

    watch(
      () => ratingType.value,
      () => {
        emit("ratingTypeChanged", {
          type: ratingType.value,
        });
      }
    );
    return {
      ratingType,
      ratingTime,
      ratingTimeList,
      ratingTypeList,
      studentGroups,
    };
  },
};
</script>
