<template>
  <div class="profile-info">
    <StudentProfileSkeleton v-if="!studentInfo?.id" />
    <div class="profile-info__wrapper" v-else-if="studentInfo">
      <div class="flex justify-center mb-5">
        <profile-image
          :imageUrl="studentInfo?.avatar"
          :canChange="true"
          size="large"
          :rank="studentInfo.rank"
          :preview="true"
          :student_id="studentInfo.id"
        />
      </div>

      <div class="profile-info__level" @click="levelsModal = true">
        <p class="profile-info__level__heading">Levels</p>
        <div class="profile-info__level-stars">
          <img
            src="@/assets/rank-star.svg"
            alt=""
            width="20"
            v-for="star in studentInfo?.rank?.task?.level"
            :key="star"
          />
        </div>
      </div>
      <h4 class="profile-info__user-name capitalize">
        {{ studentInfo?.first_name }} {{ studentInfo?.last_name }}
      </h4>
      <p class="profile-info__user-faculty mx-auto" v-if="activeGroup">
        <span v-if="studentInfo?.liga">
          {{ $t(`ligas.${studentInfo?.liga}`) }} |
        </span>

        <span v-if="activeGroup?.name"> {{ activeGroup?.name }} | </span>
        <span v-if="activeGroup?.teacher"> {{ activeGroup?.teacher }} | </span>
        <span v-if="activeGroup?.lesson_start_time">
          {{ activeGroup?.lesson_start_time.slice(0, 5) }}
        </span>
      </p>
      <p class="profile-info__user-faculty" v-else>
        {{ studentInfo?.company?.title }}
      </p>
      <div class="profile-info__block">
        <div class="profile-info__block-coins" @click="openCoinsDialog">
          <img class="coin-img" src="@/assets/Coin.svg" alt="coin-icon" />
          <p class="profile-info__block-coins-count">
            {{ studentInfo?.coins }}
          </p>
        </div>
        <div class="profile-info__block-xp">
          <img class="xp-img" src="@/assets/XP.svg" alt="xp-icon" />
          <p class="profile-info__block-xp-count">
            {{ studentInfo?.xp || "-" }}
          </p>
        </div>
      </div>
    </div>
    <el-dialog v-model="coinsDialog" align-center class="coins-history-modal">
      <coins-history @close="coinsDialog = false" />
    </el-dialog>
    <el-dialog
      v-model="levelsModal"
      align-center
      style="background-color: transparent; box-shadow: none"
    >
      <rank-levels
        :level="studentInfo?.rank?.task?.level || 0"
        :studentXp="studentInfo?.xp || 0"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { ProfileImage } from "../../entities";
import { ref, computed } from "vue";
// import { getStudentInfo } from "./api/get-student-info.api";
import RankLevels from "@/modules/RankLevels/UI/RankLevels.vue";
import "./style/profile-component.css";
import CoinsHistory from "@/widgets/CoinsHistory/CoinsHistory.vue";
import StudentProfileSkeleton from "./StudentProfileSkeleton.vue";
import { useStore } from "vuex";
import { useGtag } from "vue-gtag-next";

const coinsDialog = ref(false);
const store = useStore();

const studentInfo = computed(() => store.state.student.studentInfo);

const activeGroup = computed(() => {
  if (studentInfo.value?.groups?.length) {
    return studentInfo.value?.groups?.filter((el) => el?.status === 5).at(-1);
  } else return null;
});

const levelsModal = ref(false);

const gtag = useGtag();
const openCoinsDialog = () => {
  gtag.event("coins-history", {
    event_category: "coins",
    event_label: "coins-history",
    value: 1,
  });
  coinsDialog.value = true;
};
</script>
