<template>
  <div class="notifications">
    <el-popover
      placement="bottom-end"
      trigger="click"
      width="auto"
      popper-style="padding:0"
    >
      <template #reference>
        <el-badge :value="unReadNotifications" :show-zero="false">
          <button class="text-[22px] text-[#9e9e9e]">
            <i class="fa-regular fa-bell text"></i>
          </button>
        </el-badge>
      </template>
      <div class="max-h-[300px] relative h-full overflow-y-auto px-2 pb-2">
        <p
          class="text-lg text-[#333333] font-[700] sticky top-0 left-0 bg-white p-2 border-b border-gray-100"
        >
          {{ $t("notifications") }}
        </p>
        <TheNotification
          v-for="notification in notificationsList"
          :key="notification.id"
          :coin="notification.comment"
          :isRead="notification.is_read"
          :date="notification.created_at"
          :id="notification.uuid"
          :type="notification.notification?.type"
          :title="notification.title"
          :img="notification.image"
          @update="getNotificationsList"
        />

        <p
          class="text-md text-gray-300 text-center m-5"
          v-if="!notificationsList.length"
        >
          {{ $t("no-notifications") }}
        </p>
      </div>
    </el-popover>
    <coin-modal
      v-if="notForDisplay"
      :coin="notForDisplay?.comment"
      :id="notForDisplay.uuid"
      :img="notForDisplay.image"
      :type="notForDisplay?.notification?.type"
      :title="notForDisplay?.title"
      @update="getNotificationsList"
    />
    <!-- <StrikeModal /> -->
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import CoinModal from "@/components/NotificationModals/CoinModal.vue";
// import StrikeModal from "@/components/NotificationModals/StrikeModal.vue";
import TheNotification from "@/entities/TheNotification/TheNotification.vue";
import apiV2 from "@/apiConfig/apiV2";

const notificationsList = ref([]);

const getNotificationsList = async () => {
  notificationsList.value = await apiV2
    .get("/notification")
    .then((res) => res.data)
    .catch(() => []);
};

onMounted(() => {
  getNotificationsList();
});

const notForDisplay = ref(null);
const unReadNotifications = computed(() => {
  const filtered = notificationsList.value.filter((n) => !n.is_read);

  if (filtered?.length) {
    notForDisplay.value = filtered[0];
  } else {
    notForDisplay.value = null;
  }

  return filtered.length;
});
</script>

<style lang="scss"></style>
