<template>
  <div
    class="flex items-center gap-2 mt-2 p-2 border-b border-gray-200"
    :class="{
      'bg-[#EFF8FF] rounded-md': !isRead,
    }"
  >
    <img
      v-if="type === 'coin'"
      src="@/assets/Coin.svg"
      alt=""
      class="w-[35px] h-[35px]"
    />
    <img
      v-else-if="type === 'badge'"
      :src="mediaLink + img"
      alt=""
      class="w-[35px] h-[35px]"
    />
    <div>
      <div v-if="type === 'coin'">
        <p class="text-titleBlue text-sm font-bold" v-if="lang === 'uz'">
          <span class="text-primary500"> {{ coin }}</span> Coin bilan
          ragbatlantirildingiz
        </p>
        <p class="text-titleBlue text-sm font-bold" v-else>
          Вы получаете повышение на
          <span class="text-primary500"> {{ coin }}</span>
          монет.
        </p>
      </div>
      <div v-else-if="type === 'badge'">
        <p class="text-titleBlue text-sm font-bold" v-if="lang === 'uz'">
          <span class="text-primary500"> {{ title }}</span> Beydjik bilan
          ragbatlantirildingiz
        </p>
        <p class="text-titleBlue text-sm font-bold" v-else>
          Вы получаете повышение на<span class="text-primary500">
            {{ title }}</span
          >
          бейдж.
        </p>
      </div>
      <p class="text-[10px] text-[#9e9e9e]">
        {{ DateTime.fromISO(date).toFormat("dd-MMM-yyyy HH:mm") }}
      </p>
    </div>
  </div>
</template>
<script setup>
const lang = localStorage.getItem("lang");
import { DateTime } from "luxon";
import { mediaLink } from "@/apiConfig/mediaLink";

const props = defineProps([
  "coin",
  "isRead",
  "date",
  "id",
  "type",
  "img",
  "title",
]);
const emits = defineEmits(["update"]);
</script>
